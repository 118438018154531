/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';
import {
    FAST_ORDER,
    MY_INVOICES,
    MY_ORDERS,
    PRODUCT_AVAILABILITY_LIST as PRODUCT_AVAILABILITY_LIST_TYPE
} from 'Type/Account.type';

import {
    AFTER_ITEMS_TYPE,
    ASK_ABOUT_POPUP,
    AUTHOR_PAGE,
    BEFORE_ITEMS_TYPE,
    FAST_ADD_POPUP,
    MY_ACCOUNT_INVOICE,
    MY_ACCOUNT_INVOICES,
    MY_ACCOUNT_MENU_PAGE,
    MY_ACCOUNT_ORDER,
    PRODUCT_AVAILABILITY_LIST,
    SALABLE_QTY_ERROR_POPUP,
    SWITCH_ITEMS_TYPE
} from './Router.config';

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const MyAccountMenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/MyAccountMenuPage'));
export const SalableQtyErrorPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "salable-qty-popup" */ 'Component/SalableQtyErrorPopup'));
export const FastAddPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "salable-qty-popup" */ 'Component/FastAddPopup'));
export const AskAboutProductPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/AskAboutProductPopup'));
export const AuthorPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "author" */ 'Route/AuthorPage'));

/** @namespace Eduksiazka/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Eduksiazka/Component/Router/Component */
export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <AskAboutProductPopup />,
            position: 10,
            name: ASK_ABOUT_POPUP
        }
    ];

        [SWITCH_ITEMS_TYPE] = [
            ...this[SWITCH_ITEMS_TYPE].filter(({ name }) => name !== MY_ACCOUNT_ORDER),
            {
                component: <Route
                  path={ withStoreRegex('/sales/order/view/order_id/:orderId?/:type') }
                  render={ (props) => (
                    <MyAccount
                      { ...props }
                      selectedTab={ MY_ORDERS }
                    />
                  ) }
                />,
                position: 70,
                name: MY_ACCOUNT_ORDER
            },
            {
                component: <Route
                  path={ withStoreRegex('/product-availability-list') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ PRODUCT_AVAILABILITY_LIST_TYPE } /> }
                />,
                position: 100,
                name: PRODUCT_AVAILABILITY_LIST
            },
            {
                component: <Route path={ withStoreRegex('/account-menu') } render={ (props) => <MyAccountMenuPage { ...props } /> } />,
                position: 101,
                name: MY_ACCOUNT_MENU_PAGE
            },
            {
                component: (
                <Route
                  path={ withStoreRegex('/sales/invoice/view/invoice_id/:invoiceId?') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_INVOICES } /> }
                />
                ),
                position: 102,
                name: MY_ACCOUNT_INVOICE
            },
            {

                component: <Route
                  path={ withStoreRegex('/sales/invoice/history') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ MY_INVOICES } /> }
                />,
                position: 103,
                name: MY_ACCOUNT_INVOICES
            },
            {
                component: <Route
                  path={ withStoreRegex('/fast-order') }
                  render={ (props) => <MyAccount { ...props } selectedTab={ FAST_ORDER } /> }
                />,
                position: 104,
                name: FAST_ORDER
            },
            {
                component: <Route
                  path={ withStoreRegex('/:lang(author|autor)/:author') }
                  render={ (props) => <AuthorPage { ...props } /> }
                />,
                position: 105,
                name: AUTHOR_PAGE
            }
        ];

    [AFTER_ITEMS_TYPE] = [
        ...this[AFTER_ITEMS_TYPE],
        {
            component: <SalableQtyErrorPopup />,
            position: 30,
            name: SALABLE_QTY_ERROR_POPUP
        },
        {
            component: <FastAddPopup />,
            position: 31,
            name: FAST_ADD_POPUP
        }
    ];
}

export default Router;
